<template>
  <div v-show="$store.state.userDataIsFetched" class="dashboard">
    <h2 class="dashboard__heading">
      {{ $t("overview").heading }}
      <!-- {{ $store.state.windowWidth }}
      {{ pixelRatio }} -->
    </h2>

    <div class="dashboard__outer-container">
      <!-- <div class="dashboard__header"></div> -->
      <div class="dashboard__container">
        <div class="dashboard__left-container dashboard__left-container--1">
          <!-- <app-lottie
            :lottie="'success'"
            :speed="1000"
            class="dashboard__award"
          ></app-lottie> -->
          <!-- <img
            src="@/assets/images/diagram.svg"
            alt="Last courses icon"
            class="dashboard__award dashboard__new-course"
          /> -->
          <img
            src="@/assets/Internal/Overview/doctorsHat_n32.png"
            alt="New courses icon"
            class="dashboard__award dashboard__new-course dashboard__image--1"
          />
          <!-- was: doctorsHat_n12.png -->
          <div class="dashboard__left-inner-container">
            <span v-if="lastCourses.length === 0">
              {{ $t("overview").leftContainers[0] }}</span
            >
            <span v-if="lastCourses.length > 0 && numberOfPendingCourses > 0"
              >{{ numberOfPendingCourses }}
              {{ $t("overview").leftContainers[1] }}</span
            ><span
              v-if="lastCourses.length > 0 && numberOfPendingCourses === 0"
            >
              {{ $t("overview").leftContainers[2] }}</span
            >
          </div>
        </div>
        <div class="dashboard__last-courses-container">
          <h3 class="dashboard__subheading">
            {{ $t("overview").last_courses }}
          </h3>
          <li
            v-if="lastCourses.length === 0"
            class="dashboard__no-last-courses"
          >
            {{ $t("overview").no_courses_attended[0] }}
            <span class="dashboard__no-last-courses--big-screen">{{
              $t("overview").no_courses_attended[1]
            }}</span>
            <span class="dashboard__no-last-courses--small-screen">{{
              $t("overview").no_courses_attended[2]
            }}</span>
          </li>
          <template v-for="(lesson, index) in lastCourses" :key="lesson">
            <div
              @mousedown="
                $store.state.courseWasReachedFrom = ['home'];
                $store.state.goToLesson = lesson[0];
              "
              class="dashboard__last-courses-item"
            >
              {{ t($store.state.listOfLessonNames[lesson[0]]) }}
            </div>
            <div @mousedown="$store.state.goToLesson = lesson[0]">
              <app-pending
                v-if="lesson[1] < lesson[2] && lastCourses[index][0] !== null"
                alt="A clock marking a task in progress"
                class="dashboard__checkmark dashboard__clock"
              ></app-pending>
              <app-checkmark-blue
                v-if="lesson[1] === lesson[2] && lastCourses[index][0] !== null"
                alt="A checkmark marking a processed task"
                color="#1a7dc2"
                class="dashboard__checkmark"
              ></app-checkmark-blue>
            </div>
            <div @mousedown="$store.state.goToLesson = lesson[0]">
              <span
                v-if="lastCourses[index][0] !== null && lesson[1] < lesson[2]"
                >{{ lesson[1] }} / {{ lesson[2] }}
                {{ $t("overview").finished }}</span
              >
              <span
                v-if="lastCourses[index][0] !== null && lesson[1] === lesson[2]"
                >{{ $t("overview").completed }}</span
              >
            </div>
          </template>
        </div>
      </div>
      <div class="dashboard__container">
        <div class="dashboard__left-container dashboard__left-container--2">
          <!-- <app-lottie
            :lottie="'bell'"
            :speed="speed"
            :play="ringBell"
            class="dashboard__award dashboard__notification"
          ></app-lottie> -->
          <img
            src="@/assets/Internal/Overview/envelope_n3.png"
            alt="New courses icon"
            class="dashboard__award dashboard__new-course dashboard__image--2"
          />
          <!-- envelope_n2.png -->
          <div
            class="dashboard__number-of-unread-notifications"
            :class="{
              'dashboard__no-unread-notifications':
                numberOfUnreadNotifications === 0
            }"
          >
            {{ numberOfUnreadNotifications }}
          </div>
          <div class="dashboard__left-inner-container">
            <span v-if="notifications.length === 0">
              {{ $t("overview").leftContainers[3] }}</span
            >
            <span
              v-if="notifications.length > 0 && numberOfUnreadNotifications > 0"
              >{{ numberOfUnreadNotifications }}
              {{ $t("overview").leftContainers[4] }}</span
            >
            <span
              v-if="
                notifications.length > 0 && numberOfUnreadNotifications === 0
              "
              >{{ $t("overview").leftContainers[5] }}</span
            >
          </div>
        </div>
        <div class="dashboard__right-container dashboard__right-container--2">
          <h3 class="dashboard__container-heading">
            {{ $t("overview").notifications }}
          </h3>
          <ul class="dashboard__list dashboard__list--notifications">
            <li
              v-for="(notification, indexNotification) in notifications"
              :key="notification"
              @mousedown="openNotification(indexNotification)"
              class="dashboard__message"
              :class="{
                'dashboard__unread-message':
                  getReadStatus(indexNotification).status === 'new'
              }"
            >
              {{ t(notification.heading) }}
              <span class="dashboard__message-status">{{
                getReadStatus(indexNotification).message
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="dashboard__container">
        <div class="dashboard__left-container">
          <!-- <app-lottie
            :lottie="'course3'"
            class="dashboard__award dashboard__new-course"
          ></app-lottie> -->
          <img
            src="@/assets/Internal/Overview/cards_n2.png"
            alt="New courses icon"
            class="dashboard__award dashboard__new-course dashboard__image--3"
          />
          <!-- was: cards_n2.png -->
          <div class="dashboard__left-inner-container">
            <span v-if="$store.state.newlyPublishedCourses.length > 1">
              {{ $store.state.newlyPublishedCourses.length }}
              {{ $t("overview").leftContainers[6] }}</span
            >
            <span v-if="$store.state.newlyPublishedCourses.length === 1">
              {{ $store.state.newlyPublishedCourses.length }}
              {{ $t("overview").leftContainers[7] }}</span
            >
            <span v-if="$store.state.newlyPublishedCourses.length === 0">
              {{ $t("overview").leftContainers[8] }}</span
            >
          </div>
        </div>
        <div class="dashboard__right-container dashboard__right-container--3">
          <h3 class="dashboard__container-heading">
            {{ $t("overview").new_courses }}
          </h3>
          <ul class="dashboard__list">
            <li
              v-for="lesson in $store.state.newlyPublishedCourses"
              :key="lesson"
              @mousedown="
                $store.state.courseWasReachedFrom = ['home'];
                $store.state.goToLesson = lesson;
              "
            >
              {{ t($store.state.listOfLessonNames[lesson]) }}
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="dashboard__header"></div> -->
      <div class="dashboard__container">
        <div class="dashboard__left-container">
          <!-- <app-lottie
            :lottie="'award_1'"
            :speed="1000"
            v-if="userDataIsFetched && recentStatus === 0"
            class="dashboard__award dashboard__award--badge"
          ></app-lottie>
          <app-lottie
            :lottie="'award_2'"
            :speed="1000"
            v-if="userDataIsFetched && recentStatus === 1"
            class="dashboard__award dashboard__award--badge"
          ></app-lottie>
          <app-lottie
            :lottie="'award_3'"
            :speed="1000"
            v-if="userDataIsFetched && recentStatus === 2"
            class="dashboard__award dashboard__award--badge"
          ></app-lottie> -->
          <img
            src="@/assets/Internal/Overview/cup_n2.png"
            alt="New courses icon"
            class="dashboard__award dashboard__new-course dashboard__medal dashboard__image--4"
          />
          <!-- was: cup_n2.png -->
          <div class="dashboard__left-inner-container">
            {{ $t("overview").status[recentStatus] }}
          </div>
        </div>
        <div class="dashboard__right-container dashboard__right-container--4">
          <h3 class="dashboard__container-heading">
            {{ $t("overview").status_heading }}
          </h3>
          <ul class="dashboard__list dashboard__list--award">
            <li
              v-for="(condition, index) in listOfStatus[recentStatus]"
              :key="condition"
            >
              {{ getRequirements(index) }}
              <app-checkmark-blue
                alt="A checkmark marking a processed task"
                color="#1a7dc2"
                class="dashboard__checkmark dashboard__checkmark--requirement"
                :class="{
                  'dashboard__checkmark--unprocessed': !checkIfRequirementIsFulfilled(
                    index
                  )
                }"
              ></app-checkmark-blue>
            </li>
            <li v-if="recentStatus >= listOfStatus.length">
              {{ getRequirements() }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <app-footer class="dashboard__footer"></app-footer>
  </div>
</template>

<script>
import AppCheckmarkBlue from "@/components/Atoms/Images/SmartphoneIcons/CheckmarkBlue.vue";
import AppPending from "@/components/Atoms/Images/CourseIcons/Pending.vue";
// import AppLottie from "@/components/Views/Lottiecontainer.vue";
import AppFooter from "@/components/Templates/Footer.vue";

export default {
  name: "Overview",
  data() {
    return {
      ringBell: false,
      speed: 1,
      listOfStatus: [
        [[0, 1]],
        [
          [0, 5],
          [1, 1]
        ]
      ],
      statusNames: ["Ganz neu", "Beginner"],

      selectedLecture: "",
      selectedLectureIndex: 0,
      recentStatus: -1,
      userLectureData: {}
    };
  },
  components: {
    AppCheckmarkBlue,
    AppPending,
    // AppLottie,
    AppFooter
  },
  computed: {
    /* pixelRatio() {
      return window.devicePixelRatio;
    }, */
    userDataIsFetched() {
      return this.$store.state.userDataIsFetched;
    },
    userDataLectures() {
      return this.$store.state.userDataLectures;
    },
    lastCourses() {
      let lastThreeCourses = this.o([
        this.$store.state.listOfProgressedCourses,
        []
      ]).slice(0, 3);

      return lastThreeCourses;
    },
    numberOfPendingCourses() {
      let numberOfPending = 0;
      for (let i = 0; i < this.lastCourses.length; i++) {
        if (
          this.lastCourses[i][1] < this.lastCourses[i][2] &&
          this.lastCourses[i][0] !== null
        ) {
          numberOfPending++;
        }
      }

      return numberOfPending;
    },
    notifications() {
      if (this.$store.state.notifications.length === 0) {
        return [];
      }

      let notifications = this.$store.state.notifications;
      let shownNotifications = [];

      shownNotifications[0] = {
        heading: this.$t("notifications")["welcome_notification"]["heading"],
        text: this.$t("notifications")["welcome_notification"]["text"],
        id: 0
      };

      if (this.$store.state.indexOfNotificationOnFirstLogin === false) {
        for (let i = 0; i < notifications.length; i++) {
          if (notifications[i].important) {
            shownNotifications.unshift(notifications[i]);
          }
        }
      } else {
        for (let i = 0; i < notifications.length; i++) {
          if (
            notifications[i].id >
              this.$store.state.indexOfNotificationOnFirstLogin ||
            notifications[i].important
          ) {
            shownNotifications.unshift(notifications[i]);
          }
        }
        /* if (shownNotifications.length > 3) {
          shownNotifications.splice(-1);
        } */
      }

      if (
        this.$store.state.dateOfRecentLogin >
          this.$store.state.welcomeMessageReadAtDate + 1209600000 &&
        this.$store.state.welcomeMessageReadAtDate > 0
      ) {
        shownNotifications.splice(-1);
      }

      return shownNotifications;
    },
    numberOfUnreadNotifications() {
      let numberOfUnreadNotifications = 0;
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.getReadStatus(i).status === "new") {
          numberOfUnreadNotifications++;
        }
      }

      return numberOfUnreadNotifications;
    }
  },
  methods: {
    getTotalProgress() {
      // let listOfAllLessons = [];
      let totalNumberOfExercises = 0;
      let totalNumberOfProcessedExercises = 0;
      for (let key in this.$store.state.lessons) {
        for (let i = 0; i < this.$store.state.lessons[key].length; i++) {
          for (
            let j = 0;
            j <
            this.o([this.$store.state.lessons[key][i], "topics", "length", 0]);
            j++
          ) {
            for (
              let k = 0;
              k <
              this.o([
                this.$store.state.lessons[key][i].topics[j],
                "subtopics",
                "length",
                0
              ]);
              k++
            ) {
              totalNumberOfExercises += this.o([
                this.$store.state.lessons[key][i].topics[j].subtopics[k],
                "steps",
                0
              ]);

              if (
                this.o([
                  this.$store.state.userDataLectures,
                  this.$store.state.lessons[key][i].topics[j].subtopics[k]
                    .lesson,
                  "finished",
                  0
                ])
              ) {
                totalNumberOfProcessedExercises += this.o(
                  [
                    this.$store.state.lessons[key][i].topics[j].subtopics[k],
                    "steps",
                    0
                  ],
                  0
                );
              } else {
                totalNumberOfProcessedExercises += this.o([
                  this.$store.state.userDataLectures,
                  this.$store.state.lessons[key][i].topics[j].subtopics[k]
                    .lesson,
                  "completelyFinishedExercises",
                  0
                ]);
              }
            }
          }
        }
      }

      return this.roundCourseProgress(
        (100 * totalNumberOfProcessedExercises) / totalNumberOfExercises
      );

      // return totalNumberOfExercises + " und " + totalNumberOfProcessedExercises;

      // return listOfAllLessons;
      // let totalNumberOfExercises = 0;
      /* for (let i = 0; i < listOfAllLessons.length; i++) {
        totalNumberOfExercises += this.o([
          this.$store.state.userDataLectures,
          listOfAllLessons[i],
          "totalNumberOfExercises",
          0
        ]);
      }
      return totalNumberOfExercises; */
    },
    getNumberOfSuccessfullCoursesForStatus(requestedScoreRatio) {
      let numberOfAccurateCourses = 0;
      for (let key in this.userLectureData) {
        if (this.o([this.userLectureData[key], "finished", false])) {
          let maximumScore = this.o([
            this.userLectureData[key],
            "maximumScore",
            0
          ]);
          let totalNumberOfExercises = this.o([
            this.userLectureData[key],
            "totalNumberOfExercises",
            0
          ]);
          let scoreRatio = maximumScore / totalNumberOfExercises;
          if (scoreRatio >= requestedScoreRatio) {
            numberOfAccurateCourses++;
          }
        }
      }

      return numberOfAccurateCourses;
    },
    selectLecture(index) {
      if (index > this.$store.state.listOfProgressedLectures.length - 1) {
        return;
      }
      this.selectedLecture = this.$store.state.listOfProgressedLectures[index];
      this.selectedLectureIndex = index;
    },
    precacheLessons() {
      let listOfLessons = [];
      for (let i = 0; i < this.lastCourses.length; i++) {
        listOfLessons.push(this.lastCourses[i][0]);
      }

      for (let i = 0; i < this.$store.state.newlyPublishedCourses.length; i++) {
        if (
          listOfLessons.indexOf(this.$store.state.newlyPublishedCourses[i]) ===
          -1
        ) {
          listOfLessons.push(this.$store.state.newlyPublishedCourses[i]);
        }
      }

      this.$store.state.triggerPrecacheOfLessons = listOfLessons;
      setTimeout(() => {
        this.$store.state.triggerPrecacheOfLessons = [];
      }, 500);
    },
    getReadStatus(index) {
      if (
        this.$store.state.listOfReadNotifications[this.notifications[index].id]
      ) {
        return { status: "read", message: this.$t("overview").read };
      } else {
        return { status: "new", message: this.$t("overview").new };
      }
    },
    openNotification(index) {
      // let listOfReadNotifications = this.$store.state.listOfReadNotifications.slice();
      let listOfReadNotifications = JSON.parse(
        JSON.stringify(this.$store.state.listOfReadNotifications)
      );
      this.$store.state.shownNotification = this.notifications[index];

      this.$store.state.listOfReadNotifications[
        this.$store.state.shownNotification.id
      ] = true;

      this.toggleModal("app-notification");

      if (
        this.$store.state.shownNotification.id === 0 &&
        this.$store.state.welcomeMessageReadAtDate === 0
      ) {
        this.$store.state.welcomeMessageReadAtDate = Date.now();
      }
      if (
        listOfReadNotifications !== this.$store.state.listOfReadNotifications
      ) {
        this.$store.dispatch("saveUserDataOnDatabase");
      }
    },
    checkIfRequirementIsFulfilled(index) {
      if (this.recentStatus >= this.listOfStatus.length) {
        return false;
      }

      return (
        this.getNumberOfSuccessfullCoursesForStatus(
          this.listOfStatus[this.recentStatus][index][0]
        ) >= this.listOfStatus[this.recentStatus][index][1]
      );
    },
    getStatus() {
      let status = 0;
      for (let i = 0; i < this.listOfStatus.length; i++) {
        let statusIsAchieved = true;
        for (let j = 0; j < this.listOfStatus[i].length; j++) {
          if (
            this.getNumberOfSuccessfullCoursesForStatus(
              this.listOfStatus[i][j][0]
            ) < this.listOfStatus[i][j][1]
          ) {
            statusIsAchieved = false;
          }
        }
        if (statusIsAchieved) {
          status++;
        } else {
          break;
        }
      }

      this.recentStatus = status;
    },
    getRequirements(index) {
      if (this.recentStatus >= this.listOfStatus.length) {
        return this.$t("overview").highest_status;
      }

      let requirement = this.listOfStatus[this.recentStatus][index][0];
      let requirementNumber = this.listOfStatus[this.recentStatus][index][1];

      let requirementNumberFormulation =
        requirementNumber > 1 && requirementNumber < 13
          ? this.$t("overview").number[requirementNumber - 2]
          : requirementNumber;
      if (requirementNumber === 1) {
        requirementNumberFormulation =
          requirement === 0
            ? this.$t("overview").numberOne[0]
            : this.$t("overview").numberOne[1];
      }

      let requirementCourseFormulation = "";
      if (requirementNumber === 1) {
        requirementCourseFormulation = this.$t("overview").course[0];
      } else if (requirement === 0) {
        requirementCourseFormulation = this.$t("overview").course[1];
      } else {
        requirementCourseFormulation = this.$t("overview").course[2];
      }

      let requirementFormulation = "";
      if (requirement === 0) {
        requirementFormulation =
          this.$t("overview").requirement[0] +
          requirementNumberFormulation +
          " " +
          requirementCourseFormulation +
          this.$t("overview").requirement[1];
      } else if (requirement < 1 && requirementNumber === 1) {
        requirementFormulation =
          this.$t("overview").requirement[2] +
          requirementNumberFormulation +
          " " +
          requirementCourseFormulation +
          " " +
          100 * requirement +
          this.$t("overview").requirement[3];
      } else if (requirement === 1 && requirementNumber === 1) {
        requirementFormulation =
          this.$t("overview").requirement[2] +
          requirementNumberFormulation +
          " " +
          requirementCourseFormulation +
          this.$t("overview").requirement[5];
      } else if (requirement < 1) {
        requirementFormulation =
          this.$t("overview").requirement[2] +
          requirementNumberFormulation +
          " " +
          requirementCourseFormulation +
          this.$t("overview").requirement[4] +
          100 * requirement +
          this.$t("overview").requirement[3];
      } else {
        requirementFormulation =
          this.$t("overview").requirement[2] +
          requirementNumberFormulation +
          " " +
          requirementCourseFormulation +
          this.$t("overview").requirement[5];
      }

      return requirementFormulation;
    },
    playBellLottie() {
      setTimeout(() => {
        if (this.numberOfUnreadNotifications === 0) {
          this.speed = 1000;
          this.ringBell = true;
        } else {
          let html = document.getElementsByTagName("html")[0];
          html.style.setProperty("--number-of-unread-notifications", "''");
          setTimeout(() => {
            setTimeout(() => {
              let html = document.getElementsByTagName("html")[0];
              html.style.setProperty(
                "--number-of-unread-notifications",
                "'" + this.numberOfUnreadNotifications + "'"
              );
            }, 250);
            this.ringBell = true;
          }, 250);
        }
      }, 0);
    }
  },
  created() {
    // // // this.$store.state.coursePage = -1;

    if (this.userDataIsFetched) {
      this.playBellLottie();
    }
    if (this.$store.state.userDataIsFetched) {
      this.selectedLecture = this.$store.state.listOfProgressedLectures[0];
      this.userLectureData = this.userDataLectures;

      setTimeout(() => {
        this.precacheLessons();
      }, 0);
    }
    this.getStatus();
    this.typesetMath();

    let html = document.getElementsByTagName("html")[0];
    html.style.setProperty(
      "--number-of-unread-notifications",
      "'" + this.numberOfUnreadNotifications + "'"
    );
  },
  watch: {
    selectedLecture() {
      this.typesetMath();
    },
    userDataIsFetched(to) {
      if (to) {
        this.selectedLecture = this.$store.state.listOfProgressedLectures[0];
        this.userLectureData = this.userDataLectures;
        this.precacheLessons();

        this.getStatus();
      }
    },
    userDataLectures(to) {
      this.userLectureData = to;

      this.playBellLottie();
    },
    numberOfUnreadNotifications(to) {
      let html = document.getElementsByTagName("html")[0];
      html.style.setProperty(
        "--number-of-unread-notifications",
        "'" + to + "'"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

$container-color: #181818;
$smartphone-grey: #000;

$smartphone-background: #101010;
$smartphone-border-radius: 1.2rem;

$font-color-smartphone: #ccc;

.dashboard {
  width: 100%;
  min-height: calc(100vh + 6rem);
  padding-top: 12rem;
  color: $color-white;

  background-color: #101010;

  @include grid-template-columns;
  grid-template-rows: repeat(2, min-content);

  @include respond(tab-land) {
    padding-top: 14rem;
  }

  @include respond(phone) {
    width: 100vw;
    // padding-top: 13.75rem;
    padding-top: 5.4rem;
    background-color: $color-black;
  }

  &__heading {
    grid-column: 3 / 4;
    margin-bottom: 4.25rem;
    line-height: 1;
    font-weight: 400;

    @include respond(tab-land) {
      margin-bottom: 3.78rem;
      line-height: 1.2;
    }

    @include respond(phone) {
      display: none;
      // margin-bottom: 4rem; // was: 7rem
      // font-size: 4.6rem;
    }
  }

  &__outer-container {
    grid-column: 3 / 4;

    border-radius: 0.2rem;
    margin-bottom: 3.75rem;
    border-radius: 0.6rem;

    overflow: hidden;
    display: grid;

    background: linear-gradient(
      to right,
      #181818 0rem,
      #181818 18rem,
      $color-black 18rem,
      $color-black 100%
    );

    @include respond(phone) {
      grid-column: 1 / 6;
      background: initial;
    }
  }

  &__header {
    height: 5rem;
    border-bottom: 0.375rem solid $color-black;
    background-color: $container-color;

    padding-left: 1.5rem;
    font-family: $font-primary-bold;
    font-size: 2.2rem;
    line-height: 5rem;
    color: #eee;

    @include respond(phone) {
      display: none;
    }
  }

  &__container {
    height: 18rem;
    background-color: $color-black;

    display: grid;
    grid-template-columns: 18rem 1fr;
    border-radius: 0.6rem;

    &:not(:last-child) {
      margin-bottom: 3.5rem;

      &::after {
        @include pseudo-element;
        transform: translateY(19.5rem);
        width: $width-center;
        height: 0.5rem;
        background-color: #131313;
      }

      @include respond(tab-port) {
        margin-bottom: 5rem;

        &::after {
          transform: translateY(20.1rem);
          width: calc(100% - 6rem);
          height: 0.8rem;
        }
      }

      @include respond(phone) {
        margin-bottom: 0;

        &::after {
          content: initial;
        }
      }
    }

    &:nth-child(4) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      // border-bottom: 0.375rem solid $color-black;

      @include respond(phone) {
        border-bottom-left-radius: $smartphone-border-radius;
        border-bottom-right-radius: $smartphone-border-radius;
      }
    }

    @include respond(phone) {
      position: relative;
      margin-top: 0.75rem;
      height: initial;
      border-radius: $smartphone-border-radius;
      grid-template-columns: 1fr;

      &:not(:last-child) {
        border-bottom: none;
      }

      &:last-child {
        margin-bottom: 22.5rem;
      }
    }
  }

  &__left-container {
    position: relative;
    height: 100%;
    border: 1.2rem solid #181818;

    @include respond(phone) {
      height: 14rem;
      padding-bottom: 10rem;
      background-color: $smartphone-background;
      border: none;
      // border-top: 0.1rem solid $color-black;

      &--1 {
        height: 16.75rem;
        // border-top: none;
        // border-top-left-radius: $smartphone-border-radius;
        // border-top-right-radius: $smartphone-border-radius;
        border-radius: none;
      }
    }
  }

  &__number-of-unread-notifications {
    position: absolute;
    left: 10rem;
    top: 3rem;
    width: 1.95rem;
    height: 1.95rem;
    border-radius: 50%;
    font-family: $font-primary-bold;
    font-size: 1.4rem;
    line-height: 1.95rem;
    text-align: center;
    background-color: $color-primary-dark;

    @include respond(tab-port) {
      left: 9.75rem;
      width: 2.4rem;
      height: 2.4rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    @include respond(phone) {
      left: calc(50% + 3.95rem);
      top: 3.35rem;
      width: 3rem;
      height: 3rem;
      font-size: 2rem;
      line-height: 3rem;
    }

    &.dashboard__no-unread-notifications {
      // display: none;
      transform: scale(0);
      transition: transform 0.5s;
    }
  }

  &__left-inner-container {
    position: absolute;
    left: 0.3rem;
    bottom: 0.3rem;
    width: calc(100% - 0.6rem);
    height: 3.2rem;
    background-color: #181818;

    font-size: 1.6rem;
    line-height: 3.2rem;
    text-align: center;

    @include respond(tab-port) {
      font-size: 2rem;
    }

    @include respond(phone) {
      display: none;
      background-color: #131313;
    }
  }

  &__last-courses-container {
    width: 100%;
    height: max-content;
    padding: 1rem 2rem;
    font-size: 2rem;
    line-height: 1.15;
    color: #aaa;

    display: grid;
    grid-template-columns: 1fr 2.7rem max-content;
    grid-template-rows: repeat(1fr, 3);
    grid-column-gap: 1.8rem;
    grid-row-gap: 0.25rem;

    @include respond(tab-port) {
      grid-row-gap: 0.4rem;
      font-size: 2.6rem;
      // color: #ccc;
      color: #aaa;
    }

    @include respond(phone) {
      padding: 1rem 0.75rem 9.5rem;
      font-size: 2.6rem;
      color: $font-color-smartphone;
      background-color: $smartphone-background;

      grid-row-gap: 1rem;
    }
  }

  &__no-last-courses {
    list-style: none;
    font-size: 2rem;
    line-height: 1.2;
    color: #aaa;

    @include respond(tab-port) {
      font-size: 2.6rem;
    }

    @include respond(phone) {
      // font-size: 2.6rem;
      color: $font-color-smartphone;
    }

    &--big-screen {
      @include respond(tab-port) {
        display: none;
      }
    }

    &--small-screen {
      display: none;

      @include respond(tab-port) {
        display: inline;
      }
    }
  }

  &__last-courses-item {
    cursor: pointer;
    transition: color 0.2s;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $color-primary-light;
      }
    }
  }

  &__subheading {
    grid-column: 1 / 4;
    color: $color-white;
    margin-top: 0.9rem;
    margin-bottom: 1.1rem;
    font-size: 2.6rem;

    @include respond(tab-port) {
      // margin-bottom: 10.8rem;
      // font-family: $font-primary-bold;
      font-size: 2.8rem;
      // color: red;
    }

    @include respond(phone) {
      margin-top: 1.8rem;
      margin-bottom: 0.9rem;
      // font-family: $font-primary;
      font-size: 3.2rem;

      // color: $color-secondary;
    }
  }

  &__right-container {
    align-self: start;
    padding: 1rem 2rem;

    display: grid;
    grid-template-columns: 1fr 4.6rem max-content;

    @include respond(phone) {
      padding: 1rem 0.75rem 9.5rem;
      background-color: $smartphone-background;

      &--4 {
        border-bottom-left-radius: $smartphone-border-radius;
        border-bottom-right-radius: $smartphone-border-radius;
      }
    }
  }

  &__container-heading {
    grid-column: 1 / 4;
    font-size: 2.6rem;

    @include respond(tab-port) {
      // font-family: $font-primary-bold;
      font-size: 2.8rem;
    }

    @include respond(phone) {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      // font-family: $font-primary;
      font-size: 3.2rem;
    }
  }

  &__list {
    grid-column: 1 / 2;
    margin-top: 0.75rem;
    list-style: none;
    font-size: 2rem;
    line-height: 1.2;
    color: #aaa;

    @include respond(tab-port) {
      font-size: 2.6rem;
      line-height: 1.15;
      // color: #ccc;
      color: #aaa;
    }

    @include respond(phone) {
      // font-size: 2.6rem;
      color: $font-color-smartphone;
      // color: red;
    }

    & li {
      cursor: pointer;
      transition: color 0.2s;

      @media (pointer: coarse) {
        .dashboard__right-container--2 &:active,
        .dashboard__right-container--3 &:active {
          position: relative;
          transform-style: preserve-3d;
          user-select: none;

          &::before {
            @include pseudo-element;
            left: -0.75rem;
            top: 0;
            transform: translateZ(-1px);
            width: calc(100% + 1.5rem);
            height: 100%;
            border-radius: 0.6rem;
            background-color: $color-primary-dark;
          }
        }
      }

      &:not(:first-child) {
        margin-top: 0.8rem;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: $color-primary-light;
        }
      }

      &.dashboard__no-hover {
        cursor: initial;
      }

      &.dashboard__no-hover:hover {
        color: #aaa;
      }
    }

    &--notifications {
      grid-column: 1 / 4;
    }

    &--award {
      grid-column: 1 / 5;

      & li {
        cursor: initial;

        &:hover {
          color: #aaa;
        }
      }
    }
  }

  &__checkmark-container {
    grid-column: 2 / 3;
  }

  &__checkmark-inner-container {
    transform: translateY(1.1rem);

    display: grid;
    grid-template-rows: 1.075rem 1.075rem 1.075rem;
  }

  &__checkmark {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.1rem;

    @include respond(phone) {
      transform: translateY(0.3rem);
    }

    &--unprocessed {
      filter: brightness(1.5) invert(1) grayscale(1);

      @include respond(phone) {
        filter: invert(1) grayscale(1);
      }
    }

    &--requirement {
      float: right;
      transform: translateY(0.45rem);
    }
  }

  &__clock {
    @include respond(phone) {
      filter: brightness(1.5);
    }
  }

  &__notifications-text-container {
    padding-left: 1.6rem;
    color: #aaa;
    display: grid;
  }

  &__notification {
    position: absolute;
    left: calc(25% - 0.125rem);
    top: calc(50% + 0.55rem);
    transform: translate(-50%, -50%);
    width: 6.75rem !important;
    top: 50% !important;

    &::after {
      @include pseudo-element;
      content: var(--number-of-unread-notifications);
      right: 2.1rem;
      top: 1.725rem;
      border-radius: 50%;
      font-family: $font-primary-bold;
      font-size: 1.2rem;
    }
  }

  &__message-status {
    float: right;
  }

  &__unread-message {
    color: $color-white;
  }

  &__award {
    position: absolute;
    left: 50%;
    top: 36%;
    transform: translate(-50%, calc(-50% + 0.65rem));
    width: 7rem;

    @include respond(phone) {
      transform: translate(-50%, calc(-50% + 0.75rem)) scale(1.35);
    }

    &--badge {
      width: 7rem;

      @include respond(phone) {
        transform: translate(-50%, calc(-50% + 1rem)) scale(1.35);
      }
    }
  }

  &__new-course {
    width: 7.2rem;

    @include respond(phone) {
      width: 10rem;
      transform: translate(-50%, -14%) scale(1.35);
    }
  }

  &__medal {
    width: 5rem;
  }

  &__image {
    &--1 {
      transform: translate(-50%, -25%); // was: (-50%, -31.5%)
      width: 7.75rem;
      filter: invert(1);

      @include respond(phone) {
        transform: translate(-50%, -2.5%);
        width: 14rem;
      }
    }

    &--2 {
      width: 6.1rem;
      filter: invert(1);

      @include respond(phone) {
        transform: translate(-50%, -2.5%);
        width: 10.25rem;
      }
    }

    &--3 {
      width: 7.2rem;

      filter: invert(1);

      @include respond(phone) {
        transform: translate(-50%, -11.5%);
        width: 12.4rem;
      }
    }

    &--4 {
      transform: translate(-50%, -41.5%) scaleY(0.8);
      width: 6.5rem;
      filter: brightness(2);

      @include respond(phone) {
        transform: translate(-50%, -14%) scaleY(0.8);
        width: 10rem;
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: -6.75rem;
    width: 100%;
    padding-bottom: 1rem;

    background-color: $color-black;

    @include respond(phone) {
      position: relative;
      grid-column: 1 / 6;
      align-self: end;
      padding-bottom: 10rem;
      background-color: #131313;
    }
  }
}
</style>
